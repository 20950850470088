import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <StyledIndexPage>
      <div className="hero">
        <StaticImage
          src="../images/kayladivenere.png"
          width={522}
          quality={70}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Kayla DiVenere"
        />
      </div>

    </StyledIndexPage>
  </Layout>
)

const StyledIndexPage = styled.div`
  .hero {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export default IndexPage
